import CloseIcon from "@components/icons/CloseIcon"
import React, { useEffect, useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import "./QuestionnaireModal.scss"
import { graphql, useStaticQuery } from "gatsby"
import Stepper from "./Stepper"
import FORM_CONFIG, { SUCCESS_MSG } from "../forms/config"
import { formTracking, generateUuid, getBitrixData, isProduction, postFormData, postFormDataUpdate, sendMail, useBlock } from "@components/forms/utils"
import { questionnaireSchema, registerInterestSchema, brochureSchema } from "@components/forms/schemas"
import LanguageSelect from "./../forms/elements/LanguageSelect"
import PhoneInput from "./../forms/elements/PhoneInput"
import Recaptcha from "./../forms/elements/Recaptcha"
import TermsConditions from "./../forms/elements/TermsConditions"
import TextInput from "./../forms/elements/TextInput"
import Form from "@components/forms/elements/Form"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import { useGlobalStore } from "@stores/globalStore"
import PhoneInputOpen from "@components/forms/elements/PhoneInputOpen"
import TextAreaInput from "@components/forms/elements/TextAreaInput"
import { CheckboxInput } from "@components/forms/elements/CheckboxInput"
const QuestionnaireModal = ({cta_label, open, hidebtn
}) => {
  const { ipAddress } = useGlobalStore()
  const data = useStaticQuery(graphql`
  query {
    strapiQuestionnaireForm {
      strapi_id
      step {
        question
        option {
          label
          value
          icon_image {
            url
          }
        }
      }
      ggfx_results {
        ...STRAPI_GGFX_RESULTFragment
      }

    }
  }
`)
  const [show, setShow] = useState(open ? open :false)
  const [step, setStep] = useState(0)
  const [answer, setAnswer] = useState({
    "question1" : {"label": "Apartments", "value": "3181"},
    "question2" : {"label": "Downtown Dubai", "value": "3189"},
    "question3" : {"label": "Not yet decided", "value": "3196"},
    "question4" : {"label": "Studio", "value": "3203"},
    "question5" : {"label": "I’m an end-user", "value": "194717"},
    "question6" : {"label": "Immediately", "value": "19388"},
    "question7" : {"label": "Call", "value": "3211"},
    "question8" : {"label": "Morning", "value": "3214"},
  })
  const questionnaire = data?.strapiQuestionnaireForm

  const handleClose = () => {
    setStep(0)
    setShow(false)
  }
  const handleShow = () => setShow(true)
// useEffect(()=> {
//   if(typeof window !== "undefined" &&
//       localStorage.getItem("questionnaire") === null){
//         setTimeout(()=> {
//           handleShow()
//           localStorage.setItem("questionnaire", true)
//         }, 2000)      
//       }

// }, [])
const next = () => {
  setStep(step+1)
} 
const back = () => {
  setStep(step-1)
} 

const clickToSet = (ans) => {
  let stepval = {
    "question1" : answer.question1,
    "question2" : answer.question2,
    "question3" : answer.question3,
    "question4" : answer.question4,
    "question5" : answer.question5,
    "question6" : answer.question6,
    "question7" : answer.question7,
    "question8" : answer.question8,
  }
  if(step === 0){
    stepval.question1 = ans
  }
  else if(step === 1){
    stepval.question2 = ans
  }
  else if(step === 2){
    stepval.question3 = ans
  }
  else if(step === 3){
    stepval.question4 = ans
  }
  else if(step === 4){
    stepval.question5 = ans
  }
  else if(step === 5){
    stepval.question6 = ans
  }
  else if(step === 6){
    stepval.question7 = ans
  }
  else if(step === 7){
    stepval.question8 = ans
  }
 
  setAnswer(stepval)
}
let olddata = {};
let openFromName;
let oldUiD ;
if(open){
  const fromData = sessionStorage.getItem("fromData");
  const openFromNametemp = sessionStorage.getItem("form_name");
  const oldUiDtemp = sessionStorage.getItem("unique_id");
if(oldUiDtemp){
  oldUiD = oldUiDtemp
}
if (fromData) {
olddata = JSON.parse(fromData);
}
if(openFromNametemp){
  openFromName = openFromNametemp
}
}
const recaptchaRef = useRef()
const data1 = useEmailContent()
const formConfig = openFromName === "Register Interest" ? FORM_CONFIG.registerInterest : openFromName === "Brochure Download" ? FORM_CONFIG.brochure  : FORM_CONFIG.questionnaire
const formConfig1 = FORM_CONFIG.questionnaire
let emailContentTemp = data1?.filter((item) => item.choose_template ===  formConfig?.emailTempUser);
const emailContent = emailContentTemp[0]?.content?.data?.content;

const onSubmit = async (data) => {
    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);
    const token = await recaptchaRef.current?.executeAsync()
    
    
    
    
  if (token) {
    const unique_id = generateUuid()
    const postData = {
      first_name: data.name,
      url: window.location.href,
      unique_id: open ? oldUiD : unique_id,
      ...data,
      ...answer,
      ...formConfig,
      open: open,
      extra: { formConfig, data, answer },
      "g-recaptcha-response": token,
    }

    const bitrixData = getBitrixData(postData)
    
    const postData1 = {
      first_name: data.name,
      url: window.location.href,
      unique_id: open ? oldUiD : unique_id,
      ...data,
      ...answer,
      ...formConfig1,
      open: open,
      extra: { formConfig1, data, answer },
      "g-recaptcha-response": token,
    }

    const bitrixData1 = getBitrixData(postData1)
    const formData = new FormData()

    formData.append("data", JSON.stringify(bitrixData))

    recaptchaRef.current?.reset()
    if(isBlocked || isBlocked1){
      return;
    }
    else {
      if(open){
        postFormDataUpdate(formData).then(async () => {
          await sendMail(bitrixData1)
          sessionStorage.removeItem("fromData")
        })
      }
      else {
    postFormData(formData).then(async () => {
      await sendMail(bitrixData)
    })
  }
    formTracking({
      unique_id: open ? oldUiD : unique_id,
      eventTracking: formConfig.eventTracking,
      formName: formConfig.form_name,
    })
  }
  }
}

  return (
    <>
    {hidebtn ? null :
    <a onClick={()=> handleShow()} className="button button-orange cursur" >{cta_label}</a>}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="questionnaire-modal"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="questionnaire-modal-body">
          <div className="questionnaire-modal-header">
          <CloseIcon onClick={handleClose} />
            {step === 8 ? <Form
      className="contact-form "
      validationSchema={questionnaireSchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG}
      disable
    >
       <h4 className="form-title">Personal Details</h4>
      
      <div className="form-grid">
      <TextInput name="name" placeholder="Name" label="Name" />
      <TextInput
        name="email"
        placeholder="Email"
        type="email"
        label="Email"
      />
      <PhoneInput name="telephone" label="Phone" />
      <LanguageSelect />
      <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
    </div>
      <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>Submit Details</span>
        </button>
        <TermsConditions />
      </div>
      <Recaptcha recaptchaRef={recaptchaRef} />
    </Form>:
            <div className="step-section">
             
              {questionnaire?.step?.map((item, i) => {
                return (
                  <Stepper answer={answer} setAnswer={setAnswer} clickToSet={clickToSet} ggfx_results={questionnaire?.ggfx_results} strapi_id={questionnaire?.strapi_id} step={step} item={item} index={i} total={questionnaire?.step?.length}  />
                )
              })}
              {step === 7 && open ? <Form
      className="contact-form disable-form"
      validationSchema={openFromName === "Brochure Download" ? brochureSchema : openFromName === "Register Interest" ? registerInterestSchema : questionnaireSchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG}
      disable
    >
       <h4 className="form-title">Personal Details</h4>
      <div className="form-grid">
        <TextInput value={olddata?.name} name="name" placeholder="Name" label="Name" disable />
        <TextInput
          name="email"
          placeholder="Email"
          type="email"
          label="Email"
          value={olddata?.email}
          disable
        />
        <PhoneInputOpen defaultValue={olddata?.telephone} name="telephone" label="Phone" disable />
        <LanguageSelect disable value={olddata?.language} />
        {openFromName === "Register Interest" &&<TextAreaInput
        disable
          name="message"
          placeholder=""
          label="Message"
          value={olddata?.message}
        />}
         {openFromName === "Register Interest" &&<CheckboxInput
          name="needFiniancialAdvice"
          label="Interested in mortgage advice?"
          value={olddata?.needFiniancialAdvice}
          disable
        />}
        <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
      </div> 
     
      <Recaptcha recaptchaRef={recaptchaRef} />
      <div className={`${step === 0 ? "one" : ""} btns`}>
                {step !== 0 &&
              <a className="button button-gray" onClick={back}>Back</a>}
              
              <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>Submit Details</span>
        </button>
        <TermsConditions />
      </div>
              </div>
    </Form> :
              <div className={`${step === 0 ? "one" : ""} btns`}>
                {step !== 0 &&
              <a className="button button-gray" onClick={back}>Back</a>}
              
                <a className="button button-orange" onClick={next}>Next</a>
              </div>}
            </div>}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default QuestionnaireModal
