import React from "react"
import GGFXImage from "@components/elements/GGFXImage"

const Stepper = ({ item, index, total, step, strapi_id, ggfx_results, clickToSet, setAnswer, answer }) => {

  const { question, option } = item

  
  return (
    <div
      className="step"
    >

      {step === index &&
        <div className="question-option">
          <div className="step-label">Question {index + 1} of {total}</div>
          <h4>{question}</h4>
          <div className="option-scr">
            <div className="option-list">
              {option?.map((list, j) => {
                return (
                  <a className={`${answer?.question1?.label === list?.label || answer?.question2?.label === list?.label
                    || answer?.question3?.label === list?.label || answer?.question4?.label === list?.label
                    || answer?.question5?.label === list?.label || answer?.question6?.label === list?.label
                    || answer?.question7?.label === list?.label || answer?.question8?.label === list?.label ? "option active" : "option"} ${list.icon_image?.url ? "" : "no-img"}`} onClick={() => clickToSet(list)}>
                    <div className="img-text">
                      {list.icon_image?.url &&
                        <GGFXImage
                          ImageSrc={list.icon_image}
                          altText={list?.label}
                          imagetransforms={ggfx_results}
                          renderer="srcSet"
                          imagename="questionnaire-form.step.icon_image"
                          strapiID={strapi_id}
                        />}
                      <div className="label">{list?.label}</div>
                    </div>
                    {answer?.question1?.label === list?.label || answer?.question2?.label === list?.label
                      || answer?.question3?.label === list?.label || answer?.question4?.label === list?.label
                      || answer?.question5?.label === list?.label || answer?.question6?.label === list?.label
                      || answer?.question7?.label === list?.label || answer?.question8?.label === list?.label ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" fill="#EE7133" />
                        <path d="M12 16.1587L15.0156 19.1743C16.172 16.4457 17.8801 14.0076 20 12" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                      </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" fill="#E1E8ED" />
                        <path d="M12 16.1587L15.0156 19.1743C16.172 16.4457 17.8801 14.0076 20 12" stroke="#E1E8ED" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    }
                  </a>
                )
              })}
            </div>
          </div>
        </div>}
    </div>
  )
}

export default Stepper
